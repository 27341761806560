import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import SocialMedia from "./SocialMedia";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { UserType } from "../utils/UserType";
import { Link } from "react-router-dom";

export default function Footer() {
  const { showFooter, onNavigate } = useContext(AuthContext);
  return (
    <>
      {showFooter && (
        <div>
          <div className="flex w-screen h-max py-4 bg-bkg dark:bg-content flex-col justify-start items-start gap-[1.063rem] ">
            <div className="self-stretch h-16 ss:px-[7.5rem] ss:py-3 justify-between items-center  ss:inline-flex bg-bkg dark:bg-content flex flex-col ss:flex-row">
              {/* Social media */}
              <div className="p-2 justify-start items-start gap-5 flex">
                {/* Twitter (X) */}
                <div className="w-6 h-6 relative">
                  <a href="">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3263 1.90393H21.6998L14.3297 10.3274L23 21.7899H16.2112L10.894 14.838L4.80995 21.7899H1.43443L9.31743 12.78L1 1.90393H7.96111L12.7674 8.25826L18.3263 1.90393ZM17.1423 19.7707H19.0116L6.94539 3.81706H4.93946L17.1423 19.7707Z"
                        fill="#6E7491"
                      />
                    </svg>
                  </a>
                </div>
                {/* Instagram */}
                <div className="w-6 h-6 relative">
                  <a href="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.25 7.45455C1.25 4.04033 4.04033 1.25 7.45455 1.25H16.5455C19.9597 1.25 22.75 4.04033 22.75 7.45455V16.5455C22.75 19.9597 19.9597 22.75 16.5455 22.75H7.45455C4.04033 22.75 1.25 19.9597 1.25 16.5455V7.45455ZM7.45455 2.75C4.86876 2.75 2.75 4.86876 2.75 7.45455V16.5455C2.75 19.1312 4.86876 21.25 7.45455 21.25H16.5455C19.1312 21.25 21.25 19.1312 21.25 16.5455V7.45455C21.25 4.86876 19.1312 2.75 16.5455 2.75H7.45455ZM12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM17 7C17.5523 7 18 6.55228 18 6C18 5.44771 17.5523 5 17 5C16.4477 5 16 5.44771 16 6C16 6.55228 16.4477 7 17 7Z"
                        fill="#6E7491"
                      />
                    </svg>
                  </a>
                </div>
                {/* Facebook */}
                <div className="w-6 h-6 relative">
                  <a href="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20.0524 3H3.94765C3.70178 3.0166 3.47027 3.12177 3.29602 3.29602C3.12177 3.47027 3.0166 3.70178 3 3.94765V20.0524C3.00139 20.3033 3.10168 20.5435 3.2791 20.7209C3.45651 20.8983 3.69674 20.9986 3.94765 21H12.5686V13.9895H10.2942V11.3372H12.6628V9.34765C12.6118 8.87052 12.6666 8.388 12.8233 7.93447C12.9801 7.48095 13.2349 7.06757 13.5697 6.72379C13.9045 6.38002 14.311 6.1143 14.7602 5.94558C15.2094 5.77685 15.6903 5.70927 16.1686 5.74765C17.1162 5.74765 17.9686 5.84188 18.2524 5.84188V8.30577H16.8314C15.6942 8.30577 15.5047 8.87435 15.5047 9.63247V11.3372H18.1571L17.778 14.0848H15.4105V21H20.0524C20.3033 20.9986 20.5435 20.8983 20.7209 20.7209C20.8983 20.5435 20.9986 20.3033 21 20.0524V3.94765C20.9834 3.70178 20.8782 3.47027 20.704 3.29602C20.5297 3.12177 20.2982 3.0166 20.0524 3V3Z"
                        fill="#6E7491"
                      />
                    </svg>
                  </a>
                </div>
                {/* Linkedin */}
                <div className="w-6 h-6 relative">
                  <a href="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      viewBox="0 0 448 512"
                      className="fill-socialGrey"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>
                </div>
              </div>

              {/* Conditions générales */}
              <Link
                to="/conditions"
                className="text-right text-slate-400 text-md font-normal font-nunito-sans cursor-pointer"
                onClick={() => onNavigate()}
              >
                Conditions générales d'utilisation
              </Link>
              {/* Conditions de ventes */}
              <Link
                to="/conditions-ventes"
                className="text-right text-slate-400 text-md font-normal font-nunito-sans cursor-pointer"
                onClick={() => onNavigate()}
              >
                Conditions de ventes
              </Link>

              <div className="text-right text-slate-400 text-md font-normal font-nunito-sans">
                © 2024 EscolaLik
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
