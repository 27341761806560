import axios from 'axios';
import { API_URL } from '../config/config';

class MessageService {
  
  constructor() {
    this.baseUrl = API_URL;
  }

  async getUserLastMessages(userId) {
    try{
      const response = await axios.get(`${this.baseUrl}/messages/last/${userId}`)
      const data = response.data;
      return data;
    }
    catch(error){
      //console.log(error)
      return []
    }
  }

  async getUserDiscussions(userId){
    try{
      const response = await axios.get(`${this.baseUrl}/discussions/${userId}`)
      if(response.status === 200){
        const discussions = await response.data
        return discussions;
      }
      return []

    }catch(error){
      //console.log(error)
      return [];
    }
  }

  async getDiscussionMessages(discussionId){
    if(discussionId){

      try{
        const response = await axios.get(`${this.baseUrl}/messages/discussion/${discussionId}`)
        if(response.status === 200){
          const messages = await response.data
          return messages;
        }
        return []
  
      }catch(error){
        //console.log(error)
        return [];
      }
    }else{
      return null
    }
  }

  async getDiscussionLastMessage(discussionId){
    try{
      const response = await axios.get(`${this.baseUrl}/messages/discussion/${discussionId}/last`)
      if(response.status === 200){
        const message = await response.data
        return message;
      }
      return null

    }catch(error){
      //console.log(error)
      return null;
    }

  }
  
  async getDiscussionById(discussionId){
    try{
      const response = await axios.get(`${this.baseUrl}/discussions/${discussionId}`)
      if(response.status === 200){
        const discussion = await response.data
        return discussion;
      }
      return null

    }catch(error){
      //console.log(error)
      return [];
    }
  }

  async sendMessage(newMessage){
    try{
      const response = await axios.post(`${this.baseUrl}/messages`, newMessage)
      if(response.status === 202){
        return response.data
      }
      return null
      

    }catch(err){
      //console.log(err)
      throw err
    }
  }

  async sendReservationMessage(newMessage) {
    try {
        const response = await axios.post(`${this.baseUrl}/messages`, newMessage);
        if (response.status === 202) {
            return response.data;
        }
        throw new Error("Message not created");
    } catch (err) {
        //console.log(err);
        throw err
    }
  }

  async markMessageAsRead(id){
    try{
      const response = await axios.patch(`${this.baseUrl}/messages/${id}`)
      if(response.status === 200){
        return response.data
      }
      return null
      

    }catch(err){
      //console.log(err)
    }
  }
}

export default MessageService;
