export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const APP_SECRET = process.env.REACT_APP_APP_SECRET;
export const APP_COMMISSION = process.env.REACT_APP_APP_COMMISSION;
export const API_URL = process.env.REACT_APP_API_URL;
export const CMI_CLIENT_ID = process.env.REACT_APP_CMI_CLIENT_ID;
export const STORETYPE = process.env.REACT_APP_STORETYPE;
export const TRANTYPE = process.env.REACT_APP_TRANTYPE;
export const CURRENCY = process.env.REACT_APP_CURRENCY;
export const OK_URL = process.env.REACT_APP_OK_URL;
export const FAIL_URL = process.env.REACT_APP_FAIL_URL;
export const CALLBACK_RESPONSE = process.env.REACT_APP_CALLBACK_RESPONSE;
export const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;
export const LANG = process.env.REACT_APP_LANG;
export const MD = process.env.REACT_APP_MD;
export const HASH_ALGORITHM = process.env.REACT_APP_HASH_ALGORITHM;
export const ENCODING = process.env.REACT_APP_ENCODING;
export const CMI_URL = process.env.REACT_APP_CMI_URL;
export const USD_TO_MAD_ENDPOINT = process.env.REACT_APP_USD_TO_MAD_ENDPOINT