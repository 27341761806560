import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PaymentFailure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleFooterDisplay, handleSidebarDisplay } = useContext(AuthContext);
  handleFooterDisplay(true);
  handleSidebarDisplay(false);

  // Initial countdown value in seconds
  const initialCountdown = 15;
  
  // State for countdown
  const [countdown, setCountdown] = useState(initialCountdown);

  // Helper function to get query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const oid = searchParams.get("oid");
    const amount = searchParams.get("amount");
    return { oid, amount };
  };

  // Extract parameters
  const { oid, amount } = getQueryParams();

  // Countdown and redirect logic
  useEffect(() => {
    if(!oid){
      navigate(-1)
    }
    // Set an interval to update countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect after countdown ends
    const redirectTimeout = setTimeout(() => {
      navigate("/"); // Redirect to home
    }, initialCountdown * 1000);

    // Clear intervals and timeouts on component unmount
    return () => {
      clearInterval(interval);
      clearTimeout(redirectTimeout);
    };
  }, [oid, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-bkg dark:bg-content text-center">
        <h1 className="text-lg font-bold text-red-600 mb-4">
          Paiement échoué!
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          Veuillez réessayer ultérieurement. Vous serez redirigé vers la page principale dans {countdown} secondes. 
        </p>
    </div>
  );
};

export default PaymentFailure;
